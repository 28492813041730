import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { Helmet } from 'react-helmet';
import '../Blog.css';

class Blog extends React.Component {
  state = {
    content: '# Periler hikayeyi getiriyor...',
    isPrevDisabled: false,
    isNextDisabled: false,
    totalViews: 0,
    rating: 0,               // Kullanıcının verdiği puan
    averageRating: null,     // Ortalama puan
    ratingCount: null,       // Kaç kişi oy vermiş
    isRatingDisabled: false, // Oylama kilitli mi
    userRating: null,        // Kullanıcının verdiği puan (önceden varsa)
    themes: ['theme-sepia', 'theme-dark', 'theme-old'],
    theme: localStorage.getItem('theme') || 'theme-old',
    comments: [],            // Yorumları tutacak state
    userName: '',            // Kullanıcı adını tutacak state
    newComment: '',          // Yeni yorum state
    isNameRequired: false,   // İsim girilmedi uyarısı
    errorMessage: '',        // Hata mesajı
  };

  componentDidMount() {
    const { slug } = this.props.params;
    this.loadMarkdown(slug);
    this.checkNavigation(slug);
    this.handleViews();
    this.fetchAverageRating(slug); // Ortalama ve kullanıcı puanını al
    this.fetchComments(slug);      // Yorumları yükle
    document.body.className = this.state.theme;
  }

  componentDidUpdate(prevProps, prevState) {
    const { slug } = this.props.params;
    if (slug !== prevProps.params.slug) {
      this.loadMarkdown(slug);
      this.checkNavigation(slug);
      this.handleViews();
      this.fetchAverageRating(slug);
      this.fetchComments(slug);
    }
    
    if (prevState.theme !== this.state.theme) {
      document.body.className = this.state.theme;
      localStorage.setItem('theme', this.state.theme);
    }
  }

  removeZero = (slug) => {
    return slug.replace(/-(0\d+)$/, (match, p1) => `-${p1.replace(/^0+/, '')}`);
  };
  
  loadMarkdown = (slug) => {
    const isZero = slug.split('-').pop().startsWith('0');
  
    import(`../markdown/${this.removeZero(slug)}.md`)
      .then((module) => fetch(module.default))
      .then((res) => res.text())
      .then((text) => {
        if (isZero) {
          text = text
            .replace(/'la /g, 'ile')
            .replace(/Mecnun/g, 'Mertcan')
            .replace(/Furkan/g, 'Fatih')
            .replace(/Emre/g, 'Efe')
            .replace(/Zeyo/g, 'Zehra')
            .replace(/Senyao/g, 'Seda')
            .replace(/Ensar/g, 'Ertuğ')
            .replace(/Turkish Gamer/g, 'Tuğba')
            .replace(/Turkish/g, 'Tuğba');
  
          const titleLineIndex = text.indexOf('\n');
          if (titleLineIndex !== -1) {
            let titleLine = text.substring(0, titleLineIndex).trim();
            titleLine += ' - <a>Global Edition</a>';
            text = titleLine + text.substring(titleLineIndex);
            // TODO REACT TITLE document.title = this.state.content;  document.title = text.split('\n')[0].replace('#', '').trim();
          }
        }
  
        this.setState({ content: text });
      }).catch((err) => console.error('Markdown yüklenemedi:', err));
  };

  getAdjacentSlug = (slug, step) => {
    const isZeroPadded = slug.split('-').pop().startsWith('0');

    slug = this.removeZero(slug);
  
    const [prefix, numStr] = [slug.split('-').slice(0, -1).join('-'), slug.split('-').pop()];
    const num = parseInt(numStr, 10);
    const newNum = num + step;

    if (newNum <= 0) return null;

    const newNumStr = newNum.toString();

    let formattedNum;
    if (isZeroPadded) {
      formattedNum = newNumStr.replace(/(\d+)$/, match => {
        let padded = match.padStart(3, '0');
        
        if (padded.startsWith('00')) padded = padded.slice(1);
        
        return padded;
      });
    } else formattedNum = newNumStr;
  
    return `${prefix}-${formattedNum}`;
  };

  checkNavigation = async (slug) => {
    const prevSlug = this.getAdjacentSlug(slug, -1);
    const nextSlug = this.getAdjacentSlug(slug, 1);
    const checkExistence = async (s) => s ? await import(`../markdown/${this.removeZero(s)}.md`).then(() => true).catch(() => false) : false;

    this.setState({
      isPrevDisabled: !(await checkExistence(prevSlug)),
      isNextDisabled: !(await checkExistence(nextSlug))
    });
  };

  handleNavigation = (slug) => {
    this.loadMarkdown(slug);
    this.fetchComments(slug);
    this.checkNavigation(slug);
    this.props.history.push(`/blog/${slug}`);
  };

  handleViews = async () => {
    try {
      const response = await fetch(`https://darknight-api.vercel.app/view?slug=${this.removeZero(this.props.params.slug)}`);
      const data = await response.json();
      this.setState({
        totalViews: data.totalViews
      });
    } catch (error) {
      console.error('Görüntülenme sayısı alınırken hata oluştu:', error);
    }
  };

  submitRating = async (rating) => {
    try {
      const response = await fetch(`https://darknight-api.vercel.app/rating`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rating, slug: this.removeZero(this.props.params.slug) })
      });

      if (response.ok) {
        this.setState({ isRatingDisabled: false, userRating: rating }); // tekrar oylanabilmesi için isRatingDisabled
        this.fetchAverageRating(this.props.params.slug);
      }
    } catch (error) {
      console.error('Puan gönderme hatası:', error);
    }
  };

  fetchAverageRating = async (slug) => {
    try {
      const response = await fetch(`https://darknight-api.vercel.app/rating?slug=${this.removeZero(slug)}`);
      const data = await response.json();
      this.setState({
        averageRating: data.averageRating,
        ratingCount: data.ratingCount,
        isRatingDisabled: this.state.content.length < 999 ? true : false,  // tekrar oylanabilmesi için isRatingDisabled -> //data.userHasRated,
        userRating: data.userHasRated ? data.userRating : null,
        rating: data.userHasRated ? data.userRating : 0
      });
    } catch (error) {
      console.error('Ortalama puan alma hatası:', error);
    }
  };

  handleRating = (rating) => {
    if (!this.state.isRatingDisabled) {
      this.setState({ rating });
      this.submitRating(rating);
    }
  };

  fetchComments = async (slug) => {
    try {
      const response = await fetch(`https://darknight-api.vercel.app/comments?slug=${slug}`);
      const { comments } = await response.json();
      this.setState({ comments });
    } catch (error) {
      console.error('Yorumlar alınırken hata oluştu:', error);
    }
  };
  
  handleAddComment = async () => {
    const { slug } = this.props.params;
    const { userName, newComment } = this.state;
  
    if (!userName || !newComment) return this.setState({ errorMessage: 'Tüm alanların doldurulması zorunludur.' });
  
    try {
      const response = await fetch(`https://darknight-api.vercel.app/comment?slug=${slug}&user=${encodeURIComponent(userName)}&comment=${encodeURIComponent(newComment)}`, { method: 'POST' });
  
      if (response.ok) {
        this.setState({ newComment: '' });
        alert('Yorumun başarıyla eklendi!');
        this.fetchComments(slug);
      } else {
        this.setState({ errorMessage: 'Yorum eklerken bir hata oluştu!' });
      }
    } catch (error) {
      console.error('Yorum eklerken hata oluştu:', error);
      this.setState({ errorMessage: 'Yorum eklerken bir hata oluştu!' });
    }
  };  

  handleNameChange = (e) => this.setState({ userName: e.target.value });
  handleCommentChange = (e) => this.setState({ newComment: e.target.value });

  changeTheme = () => {
    const currentIndex = this.state.themes.indexOf(this.state.theme);
    const nextIndex = (currentIndex + 1) % this.state.themes.length;
    const nextTheme = this.state.themes[nextIndex];

    this.setState({ theme: nextTheme });
    localStorage.setItem('theme', nextTheme);
  };

  render() {
    const { slug } = this.props.params;

    const formatDate = isoDate => new Date(isoDate).toLocaleDateString('tr-TR').replaceAll('.', '-');
    const generateColorFromNumber = (n) => `#${(Math.abs(n) % 16777215).toString(16).padStart(6, '0')}`;

    return (
      <div className={`blog-post ${this.state.theme}`}>
        {/*  // TODO - <div className="view-count">
          <div>Toplam Görüntülenme: {this.state.totalViews}</div>
        </div>*/}
              
        <div className="alert" style={{ width: "100%" }}>
          Haberdar olmak için <a href="https://whatsapp.com/channel/0029VatHmfJ6WaKsWVWagm18">WhatsApp kanalı</a> üzerinden takip edin!
        </div>

        <div className="navigate">
          <a href='/blog'><img style={{ left: 20 }} src='/back.png'></img></a>
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, -1))} disabled={this.state.isPrevDisabled}>Önceki</button>
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, 1))} disabled={this.state.isNextDisabled}>Sonraki</button>
          
          <img style={{ right: 20 }} onClick={this.changeTheme} src='/sun.webp'></img>
        </div>

        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{this.state.content}</ReactMarkdown>

        <div className="navigate">
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, -1))} disabled={this.state.isPrevDisabled}>Önceki</button>
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, 1))} disabled={this.state.isNextDisabled}>Sonraki</button>
        </div>

        <div className="alert" style={{ width: "100%", maxWidth: "100%", padding: 0, overflow: "hidden" }}>
          <a href='https://taleforce.mootie.dev' style={{ textDecoration: "none" }}>
            <img style={{ maxWidth: "100%", padding: 0 }} src='https://taleforce.mootie.dev/banner.png'></img>

            <div style={{ color: "#e0e0e0", margin: "4px 12px 8px", textAlign: "justify", textDecoration: "none" }}>[Reklam] Taleforce -  Senin hikayen, senin gücün.</div>
          </a>
        </div>

        <div className="rating-box">
          <div style={{ fontSize: 25 }}>Okuyucu puanları ({this.state.ratingCount})</div>

          <div className="user-rating">
            <div className="average-rating">{this.state.averageRating?.slice(0, -1)}</div>

            <div className="star-rating">
              {[1, 2, 3, 4, 5].map((i) => (
                <span
                  key={i}
                  className={i <= this.state.rating ? 'star selected' : 'star'}
                  onClick={() => this.handleRating(i)}
                  style={{ cursor: this.state.isRatingDisabled ? 'not-allowed' : 'pointer', color: i <= this.state.rating ? 'gold' : 'gray' }}
                >
                  ★
                </span>
              ))}
            </div>
          </div>

          <div style={{ color: "#bbb" }}>{this.state.userRating ? `Verdiğiniz Puan: ${this.state.userRating}` : 'Henüz puan vermediniz.'}</div>
          
          {<div className="alert">
            Fikrini mi değiştirdin? Puanını değiştirmek artık mümkün! 
          </div>}
        </div>

        <div className="rating-box">
          {this.state.isRatingDisabled ? <></> : <div style={{ fontSize: 25 }}>Yorum Yap!</div>}

          <div className="comments-box">

            {this.state.isRatingDisabled ? <></> : <div className="comment-form">
              <input
                type="text"
                placeholder="Adınızı girin..."
                value={this.state.userName}
                onChange={this.handleNameChange}
              />
              <textarea
                placeholder="Yorumunuzu yazın..."
                value={this.state.newComment}
                onChange={this.handleCommentChange}
              ></textarea>
              <button onClick={this.handleAddComment}>Yorum Yap</button>

              {this.state.errorMessage && <div className="error-message">{this.state.errorMessage}</div>}
            </div>}

            <div style={{ fontSize: 25 }}>Okuyucu Yorumları</div>

            <div className="comments-list">
              {this.state.comments.length > 0 ? this.state.comments.map((comment, index) => (
                <div key={index} className="comment">
                  <div className="left">
                    <img
                      src={`https://ui-avatars.com/api/?name=${comment.user_name}&background=${generateColorFromNumber(comment.user_name.match(/\d+$/) ? comment.user_name.match(/\d+$/)[0] : 0).slice(1)}&color=fff`}
                      alt={comment.user_name}
                    />
                    <strong>{comment.user_name}</strong>
                  </div>

                  <div className="right">
                    <p>{comment.comment_text}</p>
                    <span className="date">{formatDate(comment.created_at)}</span>
                  </div>
                </div>
              )) : <p>Henüz yorum yapılmamış.</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (props) => <Blog {...props} params={useParams()} history={useHistory()} location={useLocation()} />;
